
import { Component, Prop, Vue } from "vue-property-decorator"
import { Phase } from "@/mixins/MissionPricing2"

@Component
export default class MissionScopeOverview extends Vue {
  @Prop() readonly widget?: boolean
  @Prop({ required: true }) readonly phases!: Phase[]
  @Prop({ required: true }) readonly phaseTotals!: number[]
  @Prop({ required: true }) readonly activityTotals!: Record<string, number>
  @Prop() readonly data!: { minPartnerValue: number; maxPartnerValue: number }
}

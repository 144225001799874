
import { Vue, Prop, Component, Emit } from "vue-property-decorator"
import { CreateStaffingMilestoneMutation, MissionStaffingMilestone } from "@/gql"
import { MissionContractDeliverable } from "@/gql"

@Component
export default class AddMilestoneModal extends Vue {
  @Prop({ required: true })
  deliverables!: MissionContractDeliverable[]

  defaultForm: {
    name: string
    description: string
    fees: number | null
    missionContractDeliverableId: number | null
  } = {
    name: "",
    description: "",
    fees: null,
    missionContractDeliverableId: null,
  }

  form = { ...this.defaultForm }

  resetForm() {
    this.$set(this, "form", { ...this.defaultForm })
    this.$refs.observer && (this.$refs.observer as any).reset()
  }

  @Emit("close")
  @Emit("input")
  onClose() {
    this.resetForm()
    return false
  }

  async onAdd() {
    const result = await this.mutate({
      mutation: CreateStaffingMilestoneMutation,
      variables: {
        ...this.form,
        feesCents: ((this.form.fees! * 100) as unknown as string) || undefined,
      },
    })

    if (result.data?.createStaffingMilestone.milestone) {
      this.saveComplete(result.data?.createStaffingMilestone.milestone)
    }
  }

  @Emit("save")
  saveComplete(milestone: MissionStaffingMilestone) {
    const data = {
      ...this.form,
      id: milestone.id,
      wasRecentlyCreated: true,
      feesCents: this.form.fees! * 100,
    }
    this.resetForm()

    return data
  }
}

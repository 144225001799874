
import { ConsultantContractPayment, ContractPayment, MissionStaffingMilestone } from "@/gql"
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator"

@Component
export default class AddConsultantPaymentForm extends Vue {
  @Prop() readonly currency!: string
  @Prop() readonly milestones!: MissionStaffingMilestone[]
  @Prop() readonly payment?: ConsultantContractPayment & { _id?: string }
  @Prop({ default: [] }) readonly payments?: ConsultantContractPayment[]

  defaultForm: {
    description: string | null
    amount: number | string | null
    percentage: number | null
    paymentType: { [key: string]: string } | null
    paymentDueType: { [key: string]: string } | null
    dueDate: string | null
    currency: string | null
    milestones: MissionStaffingMilestone[]
    typeOfPayment: string | null
  } = {
    description: null,
    amount: null,
    percentage: null,
    paymentType: null,
    dueDate: null,
    paymentDueType: null,
    currency: this.currency,
    milestones: [],
    typeOfPayment: null,
  }

  form = { ...this.defaultForm }
  cachedPayments: (ConsultantContractPayment & { _id?: string })[] = [] // local copy of payments prop

  get paymentTypes(): { text: string; value: ContractPayment }[] {
    return [
      {
        text: "Advance",
        value: ContractPayment.AdvancePayment,
      },
      {
        text: "Interim",
        value: ContractPayment.InterimPayment,
      },
      {
        text: "Final",
        value: ContractPayment.FinalPayment,
      },
    ]
  }

  get selectPaymentTypes(): { text: string; value: ContractPayment }[] {
    // get payments types that have not been created OR updating always include interim payment
    return this.paymentTypes.filter(
      (p) =>
        !(this.payments || []).map((payment) => payment.paymentType).includes(p.value) ||
        p.value === ContractPayment.InterimPayment ||
        this.payment?.paymentType == p.value
    )
  }

  get updating() {
    return !!this.payment
  }

  get typeOfPaymentSelect(): { text: string; value: string }[] {
    return [
      {
        text: "Milestone",
        value: "milestone_based",
      },
      {
        text: "Date",
        value: "due_date",
      },
    ]
  }

  get interimPaymentType() {
    return this.paymentTypes.find((p) => p.value === ContractPayment.InterimPayment)
  }

  get finalPaymentType() {
    return this.paymentTypes.find((p) => p.value === ContractPayment.FinalPayment)
  }

  get advancePaymentType() {
    return this.paymentTypes.find((p) => p.value === ContractPayment.AdvancePayment)
  }

  get milestoneTotal() {
    return this.milestones.reduce((sum, cur) => sum + cur.fees, 0)
  }

  get percentAllocated() {
    let total = this.cachedPayments.reduce((sum, cur) => sum + +(cur.percentage || 0), 0)

    if (!this.updating) {
      total += parseInt(this.form.percentage || (0 as any))
    }
    return total
  }

  get availableMilestones() {
    // const selectedIds = this.updating ? [] : this.selectedMilestones.map((m) => m.id)
    // return this.milestones.filter((m) => !selectedIds.includes(m.id))
    return this.milestones
  }

  resetForm() {
    this.form = { ...this.defaultForm }
    this.$refs.observer && (this.$refs.observer as any).reset()
  }

  @Watch("payment", {
    immediate: true,
  })
  populateForm() {
    if (this.payment) {
      // TODO: Refactor to use StaffingPositionDeliverable

      //get current milestones and update payment milestones
      // let milestones_id = this.milestones.map((milestone) => milestone.id)
      // this.payment.missionStaffingMilestones = this.payment.missionStaffingMilestones.filter(
      //   (milestone) => milestones_id.includes(milestone.id)
      // )

      // if (this.payment.missionStaffingMilestones.length > 0) {
      //   this.payment.amount = 0
      //   this.payment.amountCents = 0
      // }

      this.assignObjectVals(this.payment, this.form, { missionStaffingMilestones: "milestones" })
      // set payment type to object
      this.form.paymentType = this.paymentTypes.find((t) => t.value == this.payment!.paymentType)!

      // maybe set interim payment type
      if (
        [ContractPayment.InterimPayment, ContractPayment.FinalPayment].includes(
          this.form.paymentType.value as any
        )
      ) {
        // this.form.typeOfPayment = this.payment.missionStaffingMilestones.length
        //   ? "milestone_based"
        //   : "due_date"
      }
    } else this.resetForm()
  }

  @Watch("payments", {
    immediate: true,
  })
  onPaymentsChange() {
    this.cachedPayments = [...(this.payments || [])]
  }

  get selectedMilestones() {
    // const existingMilestones = this.cachedPayments
    //   .map((payment) => payment.missionStaffingMilestones)
    //   .flat(1)

    // return [...existingMilestones]
    return []
  }

  @Emit("done")
  addPayment() {
    const data = (({ description, amount, dueDate }) => ({
      description,
      dueDate,
      amount,
      amountCents: undefined,
      paymentType: this.form.paymentType!.value,
      percentage: Number(this.form.percentage),
      missionStaffingMilestones: this.form.milestones.map((m) => ({
        id: m.id,
        name: m.name,
        fees: m.fees,
      })),
      id: this.payment?.id,
      _id: this.payment?._id || this.generateRand(5),
    }))(this.form)

    // reset
    this.resetForm()
    return data
  }

  @Watch("form.paymentType", { immediate: true })
  onPaymentTypeChange() {
    if (this.form.paymentType?.value == "advance_payment") {
      if (this.updating) this.form.amount = this.getAmountFromPercent(this.form.percentage!)
      else this.form.percentage = 30
    }
  }
  getAmountFromPercent(percentage: number) {
    return Math.round((percentage / 100) * this.milestoneTotal)
  }

  @Watch("form.percentage", { immediate: true })
  onPercentageChange() {
    if (!this.form.percentage) return

    const parsedPercentage = parseInt(this.form.percentage as any)
    this.form.amount = this.getAmountFromPercent(parsedPercentage || 0) // change amount percentage

    if (this.updating && parsedPercentage) {
      const paymentUpdating = this.cachedPayments.find((cache) => {
        // find by id or _id
        return this.payment!.id ? cache.id == this.payment!.id : cache._id == this.payment!._id
      })

      if (paymentUpdating) {
        // update amount so allocated % is updated as well
        paymentUpdating.percentage = parsedPercentage
      }
    }
  }
}

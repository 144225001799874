
import { Component, Prop, Vue, Watch, Inject } from "vue-property-decorator"
import { ContractingEntities, Namespaces } from "@/constants"
import CurrencySelect from "@/components/widgets/input/CurrencySelect.vue"
import {
  ConsultantContract,
  CreateConsultantContractMutation,
  CreateConsultantContractMutationMutation,
  Currency,
  MissionLifecycle,
  MissionStaffingApplication,
  UpdateConsultantContractMutation,
} from "@/gql"
import EditMissionScope from "@/components/missions/MissionScopeEditor2.vue"
import { RefetchQueryDescription } from "apollo-client/core/watchQueryOptions"
import UserCard from "@/views/missions/details/UserCard.vue"
import ActivityTimeline from "@/components/widgets/timeline/ActivityTimeline.vue"
import ConsultantContractSummary from "@/components/consultant/ConsultantContractSummary.vue"
import EventBus from "@/components/EventBus"
import { Action } from "vuex-class"
import { MissionLifecycleGotoPayload } from "@/store/missionlifecycle"

type CreateConsultantContractForm = {
  currency: Currency | null | undefined
  contractDate: string | null
  commencementDate: string | null
  conclusionDate: string | null
  objective: string | null | undefined
  companyName: string | null
  address: string | null
  contractingEntity: string | null | undefined
}
@Component({
  components: {
    UserCard,
    CurrencySelect,
    ConsultantContractSummary,
    EditMissionScope,
    ActivityTimeline,
  },
})
export default class CreateContractForm extends Vue {
  @Prop({ required: true }) readonly missionLifecycle!: MissionLifecycle
  @Prop({ required: true }) readonly application!: MissionStaffingApplication

  ContractingEntities = ContractingEntities
  contractingEntity: string | null = null
  consultantName: string | null = null
  consultantRole: string | null = null
  loading = false

  form: CreateConsultantContractForm = {
    currency: null,
    contractDate: null,
    commencementDate: null,
    conclusionDate: null,
    objective: null,
    companyName: null,
    address: null,
    contractingEntity: null,
  }

  @Inject()
  readonly refetchQueries!: RefetchQueryDescription

  @Action("goto", { namespace: Namespaces.MissionLifecycle })
  goto!: (payload: MissionLifecycleGotoPayload) => Promise<MissionLifecycleGotoPayload>

  @Watch("missionLifecycle", { immediate: true })
  onMissionLifecycleChange() {
    if (this.application) {
      this.form.contractingEntity =
        this.missionLifecycle.missionProposal?.missionPricingProposal?.contractingEntity
      this.consultantName = this.application.consultant.name!
      this.consultantRole = this.application.missionStaffingPosition.name
      this.form.objective = this.missionLifecycle.missionContract!.draftContract?.projectObjective
      this.form.currency = this.missionLifecycle.missionProposal?.missionPricingProposal?.currency

      if (this.application.consultantContract) {
        const contract = this.application.consultantContract

        for (const key in this.form) {
          if (Object.prototype.hasOwnProperty.call(this.form, key)) {
            const value = contract[key as keyof ConsultantContract]

            if (value) {
              ;(this.form[key as keyof CreateConsultantContractForm] as any) = value
            }
          }
        }
      }
    }
  }

  async onSaveContract() {
    const { currency, ...payload } = this.form

    if (this.application.consultantContract) {
      this.updateContract(payload)
      return
    }

    this.loading = true
    // mutate
    const result = await this.mutate<CreateConsultantContractMutationMutation>({
      mutation: CreateConsultantContractMutation,
      variables: {
        consultantContract: {
          ...payload,
          applicationId: this.application.id,
          currencyId: this.form.currency?.id,
        },
      },
      done: () => {
        this.loading = false
      },
      refetchQueries: this.refetchQueries,
    })

    if (result.data?.createConsultantContract.consultantContract) {
      this.addSuccess("Consulant contract created successfully")
      const application = { ...this.application }

      application.consultantContract = result.data.createConsultantContract
        .consultantContract as ConsultantContract

      this.$emit("update:application", application)
      this.$emit("save", application.consultantContract)
    }
  }

  async updateContract(payload: any) {
    this.loading = true
    const result = await this.mutate({
      mutation: UpdateConsultantContractMutation,
      variables: {
        id: this.application.consultantContract!.id,
        consultantContract: {
          ...payload,
          applicationId: this.application.id,
          currencyId: this.form.currency?.id,
        },
      },
      refetchQueries: this.refetchQueries,
      done: () => {
        this.loading = false
      },
    })

    if (result.data?.updateConsultantContract.consultantContract) {
      this.addSuccess("Contract updated successfully")
      this.$emit("save", result.data?.updateConsultantContract.consultantContract)
    }
  }

  onApprovalComplete() {
    EventBus.$emit("update-contract-detail-query")
  }
}

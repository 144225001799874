import { ref, computed, Ref } from "vue"

type ReturnType = {
  currentStep: Ref<number>
  steps: number
  isFinalStep: Ref<boolean>
  nextStep: () => void
  previousStep: () => void
  goToStep: (step: number) => void
  resetSteps: () => void
}

export function useProposalSteps(totalSteps = 3): ReturnType {
  const currentStep = ref(1)

  const steps = totalSteps

  const isFinalStep = computed(() => currentStep.value === steps)

  const nextStep = () => {
    if (currentStep.value < steps) {
      currentStep.value++
    }
  }

  const previousStep = () => {
    if (currentStep.value > 1) {
      currentStep.value--
    }
  }

  const goToStep = (step: number) => {
    if (step >= 1 && step <= steps) {
      currentStep.value = step
    }
  }

  const resetSteps = () => {
    currentStep.value = 1
  }

  return {
    currentStep,
    steps,
    isFinalStep,
    nextStep,
    previousStep,
    goToStep,
    resetSteps,
  }
}

import { ApolloError } from "apollo-client"
import { PageState } from "@/constants"
import { Ref } from "vue"

type PageStateType = {
  handlePageErrorState: (error: ApolloError) => void
  handlePageState: (newState: PageState) => void
}

export const usePageState = (state: Ref<PageState>): PageStateType => {
  const handlePageErrorState = (error: ApolloError) => {
    if (error.graphQLErrors) {
      state.value = PageState.Error
    } else if (error.networkError) {
      state.value = PageState.NetworkError
    }
  }

  const handlePageState = (newState: PageState) => {
    state.value = newState
  }

  return { handlePageErrorState, handlePageState }
}

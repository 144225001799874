
import { Component, Prop, Vue } from "vue-property-decorator"
import UserAvatar from "@/components/profile/UserAvatar.vue"
import { MissionStaffingApplication } from "@/gql"

@Component({
  components: {
    UserAvatar,
  },
})
export default class ApplicationCard extends Vue {
  @Prop({ required: true }) readonly application!: MissionStaffingApplication

  onProfileViewClick() {
    this.$emit("onToggleView", "profile-view")
    this.$emit("showProfileView")
  }

  handleApplicationAction(action: string) {
    switch (action) {
      case "approve":
        this.$emit("onApplicationAction", "approve")
        break
      case "shortlist":
        this.$emit("onApplicationAction", "shortlist")

        break
      case "reject":
        this.$emit("onRejectApplication")
        break
      default:
        this.addError(`Unknown action: ${action}`)
        break
    }
  }

  get contractAction() {
    return this.application.consultantContract ? "view" : "create"
  }

  onContractAction(action: string) {
    this.$emit("onContractAction", action)
  }
}

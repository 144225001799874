
import { Vue, Prop, Component, Watch } from "vue-property-decorator"
import JobRoleSelect from "@/components/widgets/input/JobRoleSelect.vue"
import AddExecMilestoneModal from "@/components/missions/AddExecMilestoneModal.vue"
import AddConsultantPaymentForm from "@/components/forms/AddConsultantPaymentForm.vue"
import KeywordField from "../fields/KeywordField.vue"
import {
  MissionLifecycle,
  MissionStaffingPosition,
  ConsultantContractPayment,
  CreateMissionExecutionStaffingPositionMutation,
  MissionStaffingMilestone,
  UpdateMissionExecutionStaffingPositionMutation,
  DeleteConsultantContractPaymentMutation,
  DeleteStaffingMilestoneMutation,
} from "@/gql"

@Component({
  components: {
    JobRoleSelect,
    AddExecMilestoneModal,
    AddConsultantPaymentForm,
    KeywordField,
  },
})
export default class AddExecutionStaffPositionForm extends Vue {
  @Prop({ required: true })
  missionLifecycle!: MissionLifecycle

  @Prop({ required: false })
  staffPosition!: MissionStaffingPosition

  @Prop({ required: false })
  refetchQueries!: any

  readonly mutation = CreateMissionExecutionStaffingPositionMutation
  milestones: MissionStaffingMilestone[] = []
  payments: any[] = []
  showAddPaymentModal = false
  activePayment: any = null
  activeMilestone: any = null
  loading = false
  requiredTags: string[] = []
  showDeletePaymentDialog = false
  showDeleteMilestoneDialog = false
  showDeleteErrorDialog = false
  showContinuePrompt = false
  forceDelete = false
  errorMessage = ""

  defaultForm: {
    missionId: number
    name: string
    jobTitleId: number
    description: string
    milestones: MissionStaffingMilestone[] | null
    payments: any[]
    requiredExpertiseTags: string[]
  } = {
    missionId: -1,
    name: "",
    jobTitleId: 0,
    description: "",
    milestones: [],
    payments: [],
    requiredExpertiseTags: [],
  }

  form = { ...this.defaultForm }
  showAddMilestoneModal = false
  totalPaymentNumber: number | null = null
  showTooltip = false

  get currency() {
    return this.missionLifecycle?.missionProposal?.missionPricingProposal?.currency?.code || "USD"
  }

  get paymentTotal() {
    return this.payments.reduce((acc, payment) => acc + this.paymentAmount(payment), 0)
  }

  resetForm() {
    this.milestones.length = 0
    this.payments.length = 0
    this.$set(this, "form", { ...this.defaultForm })
    this.$refs.observer && (this.$refs.observer as any).reset()
  }

  onAddMilestone(milestone: MissionStaffingMilestone) {
    this.milestones.push(milestone)
    this.form.milestones = this.milestones.map((m) => m.id)
    this.showAddMilestoneModal = false
  }

  get milestoneTotal() {
    return this.milestones.reduce((sum, cur) => sum + cur.fees, 0)
  }

  paymentAmount(payment: ConsultantContractPayment) {
    return (payment.percentage || 0 / 100) * (this.milestoneTotal / 100)
  }

  onRemoveMilestone(index: number) {
    this.milestones.splice(index, 1)
    this.form.milestones = this.milestones.map((m) => m.id)
  }

  async onAddPosition(force = false) {
    if ((!this.form.milestones?.length || !this.form.payments?.length) && !force) {
      this.showContinuePrompt = true
      return
    }

    this.loading = true

    const result = await this.mutate({
      mutation: this.mutation,
      variables: {
        missionId: this.missionLifecycle.id,
        name: this.form.name,
        jobTitleId: this.form.jobTitleId,
        description: this.form.description,
        milestones: this.form.milestones,
        payments: this.form.payments,
        requiredExpertiseTags: this.form.requiredExpertiseTags,
      },
      refetchQueries: this.refetchQueries,
      done: () => {
        this.loading = false
      },
    })

    if (result.data && !result.data.createMissionExecutionStaffingPosition.error) {
      this.resetForm()

      this.addSuccess("Staff position added successfully")
      this.showContinuePrompt = false

      this.$emit("close")
      this.$emit("save")

      return result.data?.createMissionExecutionStaffingPosition.missionStaffingPosition
    }
  }

  async onUpdatePosition() {
    this.loading = true

    const result = await this.mutate({
      mutation: UpdateMissionExecutionStaffingPositionMutation,
      variables: {
        id: parseInt(this.staffPosition.id),
        name: this.form.name,
        description: this.form.description,
        milestones: this.form.milestones,
        contractPayments: this.form.payments,
        requiredExpertiseTags: this.form.requiredExpertiseTags,
      },
      refetchQueries: this.refetchQueries,
      done: () => {
        this.loading = false
      },
    })

    if (result.data?.updateMissionExecutionStaffingPosition.missionStaffingPosition) {
      this.resetForm()
      this.addSuccess("Staff position updated successfully")

      this.$emit("close")
      this.$emit("save")

      // return result.data?.updateMissionExecutionStaffingPosition.missionStaffingPosition
    }
  }

  getRequiredTagNames(tags: MissionStaffingPosition["requiredExpertise"]) {
    return tags.map((tag) => tag.name)
  }

  @Watch("staffPosition", {
    immediate: true,
  })
  populateForm() {
    if (this.staffPosition) {
      const staffPosition = JSON.parse(JSON.stringify(this.staffPosition))

      this.form.name = staffPosition.name
      this.form.jobTitleId = staffPosition.jobTitle.id
      this.form.description = staffPosition.description
      this.form.requiredExpertiseTags = this.getRequiredTagNames(staffPosition.requiredExpertise)
      const milestones = staffPosition.missionStaffingMilestones

      this.milestones = [...milestones]
      this.form.milestones = this.milestones.map((m) => m.id)
      this.payments = staffPosition.consultantContractPayments
      this.requiredTags = this.getRequiredTagNames(staffPosition.requiredExpertise)
    } else {
      this.form = { ...this.defaultForm }
      this.milestones.length = 0
    }
  }

  get deliverables(): { id: number; name: string }[] {
    let deliverables = this.missionLifecycle.missionContract?.deliverables

    return (
      deliverables?.map((deliverable) => {
        return {
          id: deliverable.id,
          name: deliverable.name || deliverable.missionScopeActivity?.name || "unnamed",
        }
      }) || []
    )
  }

  @Watch("payments", { immediate: true })
  onPaymentChange() {
    this.form.payments = this.payments.map((p) => {
      const {
        id,
        description,
        amountCents,
        paymentType,
        percentage,
        dueDate,
        missionStaffingMilestones,
      } = p

      return {
        id: id || undefined,
        description,
        amountCents: amountCents || undefined,
        paymentType,
        percentage,
        dueDate,
        missionStaffingMilestoneIds: (
          missionStaffingMilestones as {
            id: number
            name: string
          }[]
        ).map((m) => m.id),
      }
    })
  }

  @Watch("milestones")
  onMilestoneChange() {
    this.form.milestones = this.milestones.map((m) => m.id)
  }

  onAddPaymentDone(payment: { [key: string]: any }) {
    const index = this.payments.findIndex((p) => {
      return (p.id && p.id === payment.id) || (p._id && p._id === payment._id)
    })

    // update in place
    if (index > -1) this.payments.splice(index, 1, payment)
    else this.payments.push(payment)

    this.activePayment = null
    this.showAddPaymentModal = false
  }

  async onDeleteContractMilestone(milestoneIndex: number) {
    const milestone = this.milestones[milestoneIndex]

    const payment = this.payments.filter((p) => {
      return p.missionStaffingMilestoneIds?.includes(milestone.id)
    })

    if (payment.length) {
      this.showDeleteMilestoneDialog = false
      this.showDeleteErrorDialog = true
      return
    }

    const remove = () => {
      this.milestones.splice(milestoneIndex, 1)
      this.showDeleteMilestoneDialog = false
    }
    if (milestone.id) {
      this.loading = true
      const result = await this.mutate({
        mutation: DeleteStaffingMilestoneMutation,
        variables: {
          id: parseInt(milestone.id),
          forceDelete: this.forceDelete,
        },
        done: () => {
          this.loading = false
          this.showDeleteMilestoneDialog = true
        },
        error: (error) => {
          if (error.code) {
            this.errorMessage = error.message
            this.forceDelete = true
            this.showDeleteMilestoneDialog = false
          } else {
            this.addMutationError(error)
          }
        },
      })

      if (result.data && !result.data.deleteStaffingMilestone.error) {
        this.addSuccess("Milestone deleted successfully")
        remove()
      }
    } else {
      remove()
    }
  }

  async onDeleteContractPayment(paymentIndex: number) {
    const payment = this.payments[paymentIndex]

    const remove = () => {
      this.payments.splice(paymentIndex, 1)
      this.showDeletePaymentDialog = false
    }

    if (payment.id) {
      this.loading = true
      const result = await this.mutate({
        mutation: DeleteConsultantContractPaymentMutation,
        variables: {
          id: parseInt(payment.id),
        },
        done: () => {
          this.loading = false
        },
      })

      if (result.data && !result.data.deleteConsultantContractPayment.error) {
        this.addSuccess("Payment deleted successfully")
        remove()
      }
    } else {
      remove()
    }
  }
}


import { Component, Prop, Vue } from "vue-property-decorator"
import { MissionLead, MissionPricingProposal, MissionProposal } from "@/gql"
import { ContractingEntities } from "@/constants"
import Printable from "@/components/layout/Printable.vue"
import { Phase, UnallocatedPricingActivity } from "@/mixins/MissionPricing2"
import { useAccountContext } from "@/hooks/useAccountContext"

@Component({
  components: {
    Printable,
  },
})
export default class PricingProposalPrintable extends Vue {
  @Prop({ required: true }) readonly missionLead!: MissionLead
  @Prop({ required: true }) readonly pricingProposal!: MissionPricingProposal
  @Prop() readonly widget?: boolean
  @Prop() readonly phases!: Phase[]
  @Prop() readonly getPhaseFinal!: () => void
  @Prop() readonly unallocatedPricingActivities!: UnallocatedPricingActivity[]
  @Prop() readonly totalCostToClient!: number
  @Prop() readonly otherCostDistributions!: number[][]
  @Prop() readonly otherCostTotals!: number[]
  @Prop({ required: false }) readonly missionProposal!: MissionProposal

  @Prop({
    default: () => ({
      hideTools: false,
    }),
  })
  readonly printableDisplayConfig?: {
    hideTools: boolean
  }

  get ContractingEntities() {
    return ContractingEntities
  }

  get pricingActivities() {
    return [...this.pricingProposal.activities]
  }

  get totalCost() {
    return this.pricingActivities
      .map((a) => (a.quantity || 1) * (a.unitCost || 0))
      .reduce((sum, cur) => sum + cur, 0)
  }

  get isClient() {
    const { isClient } = useAccountContext()
    return isClient
  }

  approveMissionProposal() {
    this.$emit("approveMissionProposal")
  }

  rejectMissionProposal() {
    this.$emit("rejectMissionProposal")
  }
}

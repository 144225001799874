
import { Component, Prop, Vue, Emit, Watch } from "vue-property-decorator"

@Component
export default class AppEditorWindow extends Vue {
  @Prop({ default: true }) readonly isOpen!: boolean
  @Prop({ required: true }) readonly default!: string // default section
  @Prop({ required: true }) readonly title!: string
  @Prop({ required: false, default: "v2" }) readonly version?: string
  @Prop({ required: false, default: "ScopeEditor" }) readonly app?: string
  @Prop({ required: true }) readonly pages!: string[]

  next = "Next"
  back = "Back"

  saved = false
  saving = false
  page: string | null = null
  _timeout: number | undefined

  save() {
    this.saved = true
    this.saving = false

    clearTimeout(this._timeout)

    this._timeout = window.setTimeout(() => {
      this.saved = false
    }, 4000)
  }

  applySaving(value = true) {
    this.saving = value
  }

  @Emit("change")
  changePage(page: string | null) {
    return page
  }

  get pageIndex() {
    return this.pages.findIndex((p) => p === this.page)!
  }

  onChangePage(direction: string) {
    if (direction === "right") {
      if (this.pageIndex == this.pages.length - 1) return this.onClose()
      this.page = this.pages[this.pageIndex + 1]
    }

    if (direction == "left") {
      if (this.pageIndex == 0) return this.onClose()
      this.page = this.pages[this.pageIndex - 1]
    }

    this.next = this.pageIndex == this.pages.length - 1 ? "Close" : "Next"
  }

  @Watch("page")
  onPageChange() {
    this.changePage(this.page)
  }

  onClose() {
    this.$emit("close")
  }

  mounted() {
    this.page = this.default
  }
}
